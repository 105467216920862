import { CTA_BUTTON_COPY, CTA_LINK } from "components/data/landing-page-data";
import {
  SectionContainer,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  MainParagraph,
  TitleMed,
  TitleSmall,
} from "components/rhombus-UI/theme/typography";
import { css } from "linaria";
import { styled } from "linaria/react";
import React from "react";
import { Mousewheel, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import RhombusButton from "../RhombusButton";
import { graphql, StaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import useIsMobile from "hooks/useIsMobile";

const SliderSection = styled(SectionContainer)`
  padding-left: 0;
  padding-right: 0;
  overflow: visible;
  > h2,
  h3 {
    padding: 0 2.5rem;
  }
  @media (max-width: 600px) {
    > h2,
    h3 {
      padding: 0 1.25rem;
    }
  }
`;

const TitleContainer = styled(TextContainer)`
  text-align: center;
`;

export const SliderSectionInner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
`;

const CarouselContainer = styled.div`
  width: 100%;
  display: flex;
  .swiper {
    padding: 0 2.5rem !important;
    @media (max-width: 600px) {
      padding: 0 1.25rem !important;
    }
  }
  .swiper-wrapper {
    width: 100% !important;
    transform-style: preserve-3d !important;
    -webkit-transform-style: preserve-3d !important;
    backface-visibility: hidden !important;
    -webkit-backface-visibility: hidden !important;
    padding-bottom: 2.5rem;
  }
  .swiper-scrollbar.swiper-scrollbar-horizontal {
    height: 0.5rem;
    width: 50%;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    background-color: var(--nuetral-200);
    border-radius: 2px;
    .swiper-scrollbar-drag {
      background: var(--blue-900);
      width: 100%;
      cursor: grab;
      border-radius: 2px;
    }
  }
`;

const slideStyles = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 448px;
  height: 573px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 2px solid var(--nuetral-200);
  gap: 24px;
  overflow: hidden;
  @media (max-width: 600px) {
    height: 476px;
  }
`;

const CardHeader = styled.div`
  height: 245px;
  width: 100%;
  flex-shrink: 0;
  background: var(--nuetral-200);
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px) {
    height: 174px;
  }
`;

const CardBody = styled.div`
  flex-grow: 1;
  width: calc(100% - 2.5rem);
  align-self: center;
  overflow: hidden;
  * {
    color: var(--blue-900);
  }
`;

const imageStyles = css`
  height: 100%;
  width: 100%;
`;

const SLIDES = [
  {
    title: "Smart Cameras",
    subTitle: "Protect What Matters Most with Powerful Camera Solutions",
    description: `The Rhombus suite of dome, fisheye, and bullet cameras deliver exceptional performance and reliability for businesses of all sizes. Capture, detect, and manage security events from a single pane of glass.`,
  },
  {
    title: "AI Analytics",
    subTitle: "Stay in the Know with Real-Time, AI-Powered Smart Alerts",
    description: `Save time, accelerate investigations, and receive real-time intelligent alerts with Rhombus AI Analytics. Through machine-learning, the system automatically learns what ‘normal’ looks like in your spaces and becomes smarter and more effective.`,
  },
  {
    title: "Access Control",
    subTitle: "Unify Building Management Like Never Before",

    description: `Pair seamlessly with the Rhombus Platform for all-in-one security at scale. Manage all aspects of your spaces from a single pane of glass to streamline operations, eliminate complexity, and improve safety.`,
  },
  {
    title: "Alarm Monitoring",
    subTitle:
      "Protect Your Spaces Around the Clock with Professional Monitoring",
    description: `Smart alarms, real agents. Protect your buildings 24/7/365 with modern alarm monitoring that combines automatic security alerts with live monitoring services.`,
  },
  {
    title: "Environmental Monitoring",
    subTitle: "Create Healthier Spaces and Manage Climate-Controlled Areas",
    description: `Continuously monitor air quality, detect vape, and manage temperature, humidity, and more with integrated IoT sensors. Track and gain insight into environmental conditions with real-time alerts and synchronized video.`,
  },
];

export default function RhombusPlatformSlider({ buttonType = "landing-v2" }) {
  const { isMobile } = useIsMobile(600);
  const renderContent = data => {
    const amImage = isMobile ? data.frame4Mobile : data.frame4;
    const IMAGES = [
      data.frame1,
      data.frame2,
      data.frame3,
      amImage,
      data.frame5,
    ];
    return (
      <SliderSection id="platform">
        <SliderSectionInner>
          <TitleContainer>
            <TitleMed fontSize="40px">An All-in-One Platform</TitleMed>
          </TitleContainer>
          <CarouselContainer>
            <Swiper
              slidesPerView="auto"
              centeredSlides
              centeredSlidesBounds
              spaceBetween={20}
              scrollbar={{ draggable: true }}
              mousewheel={{ forceToAxis: true }}
              modules={[Scrollbar, Mousewheel]}
            >
              {SLIDES.map((slide, index) => {
                const { title, subTitle, description } = slide;
                const image = IMAGES[index];
                return (
                  <SwiperSlide
                    className={slideStyles}
                    key={`${title}-${index}`}
                  >
                    <CardHeader>
                      <GatsbyImage
                        image={getImage(image)}
                        alt={title}
                        objectFit={
                          isMobile && index === 3 ? "cover" : "contain"
                        }
                        objectPosition={index === 3 ? "right" : "center"}
                        className={imageStyles}
                      />
                    </CardHeader>
                    <CardBody>
                      <TextContainer>
                        <TitleMed>{title}</TitleMed>
                        <TitleSmall style={{ fontWeight: 500 }}>
                          {subTitle}
                        </TitleSmall>
                        <MainParagraph style={{ marginTop: "0.75rem" }}>
                          {description}
                        </MainParagraph>
                      </TextContainer>
                    </CardBody>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </CarouselContainer>
          <RhombusButton
            path={CTA_LINK}
            type={buttonType}
            title={CTA_BUTTON_COPY}
          />
        </SliderSectionInner>
      </SliderSection>
    );
  };
  const GET_IMAGES = graphql`
    query {
      frame1: file(
        relativePath: {
          eq: "components/landing-pages/surveillance/assets/smart-cameras.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      frame2: file(
        relativePath: {
          eq: "components/landing-pages/surveillance/assets/ai-analytics.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      frame3: file(
        relativePath: {
          eq: "components/landing-pages/surveillance/assets/access-control.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      frame5: file(
        relativePath: {
          eq: "components/landing-pages/surveillance/assets/enviromental.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      frame4: file(
        relativePath: {
          eq: "components/landing-pages/surveillance/assets/alarm-monitoring.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      frame4Mobile: file(
        relativePath: {
          eq: "components/landing-pages/surveillance/assets/am-slide-mobile.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `;

  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
