import React from "react";
import SurveillanceHeroV2 from "./SurveillanceHeroV2";
import LogoSliderV2 from "components/common/LogoSliderV2";
import ReviewSliderSection from "components/common/sliders/ReviewSliderSection";
import TrialBanner from "components/common/footer-banners/TrialBanner";
import { CTA_BUTTON_COPY, CTA_LINK } from "components/data/landing-page-data";
import RhombusPlatformFeatures from "components/common/RhombusPlatformFeatures";
import RhombusPlatformSlider from "components/common/sliders/RhombusPlatformSlider";

export default function SurveillancePageV3() {
  return (
    <>
      <SurveillanceHeroV2 />
      <LogoSliderV2 title="Trusted by Industry Leaders" />
      <RhombusPlatformSlider />
      <RhombusPlatformFeatures />
      <ReviewSliderSection />
      <TrialBanner darkMode buttonCopy={CTA_BUTTON_COPY} ctaLink={CTA_LINK} />
    </>
  );
}
