import { FORM_IDS } from "components/data/landing-page-data";
import FormWrapper from "components/form/FormWrapper";
import RhombusForm from "components/form/RhombusForm";
import {
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import { styled } from "linaria/react";
import React, { useMemo } from "react";
import {
  MainParagraph,
  PageTitle,
} from "components/rhombus-UI/theme/typography";
import useIsMobile from "hooks/useIsMobile";
import { useAtomValue } from "jotai";
import { isExpandedAtom } from "components/form/atoms";

const HeroContainer = styled(SectionContainer)`
  background: var(--blue-800);
  overflow: visible;
  min-height: 675px;
  padding: 5rem 2.5rem;
  @media (max-width: 600px) {
    padding: 5rem 1.25rem;
  }
`;

const HeroSectionInner = styled(SectionInner)`
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

const HeroLeft = styled(SectionCol)`
  flex-direction: column;
  justify-content: center;
  gap: 2.5rem;
  @media (max-width: 1200px) {
    width: 100%;
    align-items: center;
  }
  @media (max-width: 600px) {
    padding: 0;
  }
`;

const HeroRight = styled(SectionCol)`
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 1200px) {
    width: 100%;
    justify-content: center;
  }
`;

const CenterTextContainer = styled(TextContainer)`
  max-width: 576px;
  @media (max-width: 600px) {
    h1 {
      font-size: 32px;
    }
  }
`;

const StyledGif = styled.img`
  width: 100%;
  object-fit: contain;
  object-position: center;
  will-change: transform;
  transform: translateZ(0);
  @media (max-width: 1200px) {
    max-width: 576px;
  }
`;

export default function SurveillanceHeroV2() {
  const { isMobile } = useIsMobile(1200);
  const isFormExpanded = useAtomValue(isExpandedAtom);
  const videoUrl =
    "https://prodapp2stack-core1mediaassetss3bucket-npqqyyhjtsvh.s3.us-west-2.amazonaws.com/surveillance-video-trans.mov";

  const title = useMemo(() => {
    return isFormExpanded
      ? "See Rhombus in action"
      : "Smart Surveillance for All Your Business Properties";
  }, [isFormExpanded]);

  const blurb = useMemo(() => {
    return isFormExpanded
      ? "Schedule a 30-minute product demo with expert Q&A."
      : "Monitor all locations, automatically identify security events, and resolve incidents in real time with Rhombus' AI and cloud-based solution.";
  }, [isFormExpanded]);

  return (
    <HeroContainer id="top">
      <HeroSectionInner>
        <HeroLeft>
          <CenterTextContainer>
            <PageTitle color="var(--white)">{title}</PageTitle>
            <MainParagraph color="var(--white)">{blurb}</MainParagraph>
          </CenterTextContainer>
          <FormWrapper width={isMobile ? "576px" : "550px"} darkMode>
            <RhombusForm
              formId={FORM_IDS.LIVE_DEMO}
              useFormExpansion
              useExpandOnEmail
              useSecondaryButton
              darkMode
            />
          </FormWrapper>
        </HeroLeft>
        <HeroRight>
          <StyledGif src="https://prodapp2stack-core1mediaassetss3bucket-npqqyyhjtsvh.s3.us-west-2.amazonaws.com/surveillance-hero-gif.gif" />
        </HeroRight>
      </HeroSectionInner>
    </HeroContainer>
  );
}
